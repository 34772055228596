<template>
  <div>
    <a
      href="javascript:void(0)"
      class="text-info fs-1800"
      @click="fnShowModal()"
      ><i class="fas fa-shopping-cart"></i
      ><span class="badge badge-danger fs-700 cat-number">{{
        fnGetNumberOfProductSelected()
      }}</span></a
    >

    <b-modal ref="modal" hide-footer title="Selected products">
      <div class="d-block">
        <template v-for="(row, index) in fnGetProductsCategory()">
          <dl class="row" :key="(row, index)">
            <dd class="col-sm-4 text-black-60 fw-600">
              {{ row }}
            </dd>
            <dd class="col-sm-8">
              <template v-for="(row2, index2) in fnGetProducts(row)">
                <ul :key="(row2, index2)" class="mb-0">
                  <li>{{ row2.name }}</li>
                </ul>
              </template>
            </dd>
          </dl>
        </template>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ModalShowSelectedProducts",
  props: ["productCategory"],
  data() {
    return {
      selectedProductIds: [],
      optionsProducts: [],
    };
  },
  computed: {
    ...mapGetters(["getProjectSelectedProductArrayObj"]),
  },
  methods: {
    ...mapActions(["fetchProjectSelectedProduct"]),
    fnShowModal() {
      this.$refs.modal.show();
    },
    /**
     * To get number of Product selected
     */
    fnGetNumberOfProductSelected() {
      return this.getProjectSelectedProductArrayObj.length;
    },
    /**
     * To get Category name with array
     */
    fnGetProductsCategory() {
      let arr = this.getProjectSelectedProductArrayObj;
      let result = Object.groupBy(
        arr,
        ({ product_category }) => product_category
      );
      return Object.keys(result);
    },
    /**
     * To get array of products according to category
     * @param category
     */
    fnGetProducts(category) {
      let arr = this.getProjectSelectedProductArrayObj;
      let result = Object.groupBy(
        arr,
        ({ product_category }) => product_category
      );
      return result[category];
    },
  },
  watch: {
    getProjectSelectedProductArrayObj(newValue) {
      this.fnGetNumberOfProductSelected();
    },
  },
};
</script>
<style scoped>
.cat-number {
  margin-left: -10px;
}
</style>
