<template>
  <div class="card">
    <div class="card-body">
      <div class="row" v-if="productCategory">
        <div class="col-12 col-md-8 col-lg-9">
          <div class="info-box-text fs-1200 fw-600">
            {{ productCategory.name }}
            <p class="fs-800 fw-400 text-black-50 mb-0">
              Number of products:
              <span class="badge badge-primary">{{
                fnGetNumberOfProducts(productCategory.id)
              }}</span>
            </p>
            <p class="fs-800 fw-400 text-black-50">
              Product selected:
              <span class="badge badge-success">{{
                fnGetNumberOfProductsSelected(productCategory.id)
              }}</span>
            </p>
          </div>
        </div>
        <appModalMultipleProductSelect
          :productCategory="productCategory"
        ></appModalMultipleProductSelect>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import appModalMultipleProductSelect from "../../components/project/ModalMultipleProductSelect";
export default {
  name: "ProductCard",
  props: ["productCategory"],
  components: {
    appModalMultipleProductSelect,
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters(["getProductList", "getProjectSelectedProductArrayObj"]),
  },
  methods: {
    /**
     * To Get number of products according to category id
     * @param categoryId
     */
    fnGetNumberOfProducts(categoryId) {
      let productList = this.getProductList;

      const count = productList.reduce((counter, obj) => {
        if (obj.product_category_id == categoryId) counter += 1;
        return counter;
      }, 0);

      return count;
    },
    /**
     * What we do here, once we find selected products array of object filter into based on category id
     * and find the number of selected products
     * @param categoryId
     */
    fnGetNumberOfProductsSelected(categoryId) {
      let arrayObj = this.getProjectSelectedProductArrayObj;

      let selectedProduct = arrayObj.filter((item) => {
        return item.product_category_id == categoryId;
      });

      return selectedProduct.length;
    },
  },
  watch: {
    /**
     * What we do here, monitoring each time once add or remove product and update the count
     * @param newValue
     */
    getProjectSelectedProductArrayObj(newValue) {
      this.fnGetNumberOfProductsSelected(this.productCategory.id);
    },
  },
};
</script>
<style lang="scss" scoped></style>
