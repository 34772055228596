<template>
  <div class="p-5">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4>Project <span class="fs-800">(new)</span></h4>
          </div>
          <div class="card-body">
            <dl class="row" v-if="getUserFromId">
              <dt class="col-sm-12">
                <p class="title-sub-info-p">Customer</p>
              </dt>
              <dt class="col-sm-1 text-black-60 fw-600">Id</dt>
              <dd class="col-sm-11">{{ customerId }}</dd>
              <dt class="col-sm-1 text-black-60 fw-600">Name</dt>
              <dd class="col-sm-11">
                {{ getUserFromId.first_name }} {{ getUserFromId.last_name }}
              </dd>
              <dt class="col-sm-1 text-black-60 fw-600">Email</dt>
              <dd class="col-sm-11">{{ getUserFromId.email }}</dd>
              <dt class="col-sm-1 text-black-60 fw-600">Mobile</dt>
              <dd class="col-sm-11">
                {{ getUserFromId.mobile_no }}
              </dd>
              <dt class="col-sm-1 text-black-60 fw-600">Address</dt>
              <dd class="col-sm-11">
                <span v-if="getUserFromId.unit_no"
                  >{{ getUserFromId.unit_no }},
                </span>
                <span v-if="getUserFromId.street_no"
                  >{{ getUserFromId.street_no }},
                </span>
                <span v-if="getUserFromId.street"
                  >{{ getUserFromId.street }},
                </span>
                <span v-if="getUserFromId.suburb">
                  {{ getUserFromId.suburb }},
                </span>
                <span v-if="getUserFromId.state_code"
                  >{{ getUserFromId.state_code }},
                </span>
                <span v-if="getUserFromId.post_code">
                  {{ getUserFromId.post_code }},
                </span>
                <span v-if="getUserFromId.country_code">
                  {{ getUserFromId.country_code }}
                </span>
              </dd>
            </dl>

            <hr />

            <div class="row mt-3">
              <div class="col-12 mb-3">
                <div class="row">
                  <div class="col-6 text-left">
                    <p class="title-sub-info-p">Products</p>
                  </div>
                  <div class="col-6 text-right">
                    <appModalShowSelectedProducts></appModalShowSelectedProducts>
                  </div>
                </div>
              </div>
              <template v-for="item in getProductCategoryList">
                <div class="col-lg-4 col-md-4 col-12" :key="item.id">
                  <appProductCard :productCategory="item"></appProductCard>
                </div>
              </template>
            </div>

            <div class="row mt-3">
              <div class="col-12 text-right">
                <button
                  class="btn btn-secondary btn-sm mr-2"
                  @click="fnCancel()"
                >
                  Cancel
                </button>
                <button class="btn btn-info btn-sm" @click="fnCreate()">
                  <b-spinner small v-if="isLoading" class="mr-2"></b-spinner
                  >Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as authService from "../../services/auth.service";
import * as encodeDecodeService from "../../services/encodeDecode.service";
import * as projectService from "../../services/project.service";
import * as messageService from "../../services/message.service";
import appProductCard from "../../components/project/ProductCard.vue";
import appModalShowSelectedProducts from "../../components/project/ModalShowSelectedProducts.vue";
export default {
  components: {
    appProductCard,
    appModalShowSelectedProducts,
  },
  data: function () {
    return {
      isLoading: false,
      loginUserObj: {},
      selectedProducts: [],
      checkedNames: [],
      customerId: encodeDecodeService.cyDecrypt(this.$route.params.user_id),
      arrayMultipleItemsInMainProducts: [],
    };
  },
  computed: {
    ...mapGetters([
      "getUserFromId",
      "getProductCategoryList",
      "getProjectSelectedProductArrayObj",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchProductList",
      "fetchUserFromId",
      "fetchProductCategoryList",
      "fetchProjectSelectedProduct",
    ]),
    /**
     * Select Product
     * @param - id, Product id
     */
    fnSelectProduct(product) {
      let arr = [];
      arr = this.selectedProducts;
      const { id, name, product_category, product_category_id, unit_price } =
        product;

      if (arr.length === 0) {
        this.selectedProducts.push({
          id,
          name,
          product_category,
          product_category_id,
          unit_price,
          status: "",
        });
      } else {
        let found = false;
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].id == id) {
            found = true;
            break;
          }
        }

        if (found == false) {
          this.selectedProducts.push({
            id,
            name,
            product_category,
            product_category_id,
            unit_price,
            status: "",
          });
        }
      }
      return this.selectedProducts;
    },
    /**
     *  Check Product has selected
     *  @param - id, Product id
     */
    fnCheckProductSelected(id) {
      let arr = [];
      arr = this.selectedProducts;
      let found = false;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id == id) {
          found = true;
          break;
        }
      }

      return found;
    },
    /**
     * Unselect Product
     * @param - id. Product id
     */
    fnUnSelectProduct(id) {
      let selectedProducts = [];
      let filtered = [];
      selectedProducts = this.selectedProducts;

      filtered = selectedProducts.filter(function (value) {
        return value.id != id;
      });
      this.fnCheckProductSelected(id);
      this.selectedProducts = filtered;

      return filtered;
    },
    /**
     * Create new project
     */
    fnCreate: async function () {
      try {
        // Progress bar
        this.$Progress.start();

        this.isLoading = true;

        const response = await projectService.add({
          user_id: this.customerId,
          cm_id: this.loginUserObj.parent_id,
          products: this.getProjectSelectedProductArrayObj,
        });
        this.errors = {};

        this.isLoading = false;

        // Progress bar
        this.$Progress.finish();

        if (response.data.status_code == 200) {
          messageService.fnSweetAlertSuccessToast(
            "Project",
            "Created successfully"
          );

          this.$router.push({
            name: "projectListCM",
          });
        }
      } catch (error) {
        switch (error.response.status) {
          case 401:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Project",
              error.response.data.message
            );
            break;
          case 403:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Project",
              error.response.data.message
            );
            break;
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Project",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "Project",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Project",
              "Something wrong, please try again!"
            );
            break;
        }

        // Progress fail (error)
        this.$Progress.fail();
        this.isLoading = false;
      }
    },
    /**
     * Cancel project
     */
    fnCancel() {
      this.$router.push({
        name: "projectListCM",
      });
    },
  },

  async created() {
    // Progress bar
    this.$Progress.start();
    this.loginUserObj = authService.getUserFromToken();
    await this.fetchProductCategoryList();
    await this.fetchProductList();
    await this.fetchUserFromId(this.customerId);
    await this.fetchProjectSelectedProduct([]);
  },
};
</script>
