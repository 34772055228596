<template>
  <div>
    <button @click="fnShowModal()" class="btn btn-sm btn-outline-info">
      Select product
    </button>

    <b-modal ref="modal" hide-footer :title="productCategory.name">
      <div class="d-block">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            v-model="selectedProductIds"
            :options="optionsProducts"
            value-field="id"
            text-field="name"
            :aria-describedby="ariaDescribedby"
            size="lg"
            stacked
            @change.native="fnCheckBoxChange($event)"
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ModalMultipleProductSelect",
  props: ["productCategory"],
  data() {
    return {
      selectedProductIds: [],
      optionsProducts: [],
    };
  },
  computed: {
    ...mapGetters(["getProductList", "getProjectSelectedProduct"]),
  },
  methods: {
    ...mapActions(["fetchProjectSelectedProduct"]),
    fnShowModal() {
      this.fnGetProducts();
      this.$refs.modal.show();
    },
    /**
     * Get Products according to category
     */
    fnGetProducts() {
      let getPList = this.getProductList;
      const products = getPList.filter(
        (item) => item.product_category_id == this.productCategory.id
      );

      this.optionsProducts = products;
    },
    /**
     * According to event check or uncheck
     * selected product id will collect in array
     * and sore then in fetchProjectSelectedProduct  will updated array of items id
     * @param event
     */
    fnCheckBoxChange(event) {
      let newArray = [];
      if (!event.target.checked) {
        let getValue = event.target.value;

        let oldArr = this.getProjectSelectedProduct;

        newArray = oldArr.filter((item) => item != getValue);

        this.fetchProjectSelectedProduct(newArray);
      }

      let arr = [...this.getProjectSelectedProduct, ...this.selectedProductIds];
      newArray = [...new Set(arr)];

      this.fetchProjectSelectedProduct(newArray);
    },
  },
};
</script>
